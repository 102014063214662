import dynamic from 'next/dynamic'

import { Container, Row, Column } from './base/gridview'

const Accordion = dynamic(() => import('./widgets/accordion'))
const Answers = dynamic(() => import('./widgets/answers'))
const Button = dynamic(() => import('./base/button'))
const ChargeLocation = dynamic(() => import('./widgets/charge-location'))
const ChargeStation = dynamic(() => import('./widgets/charge-station'))
const ChargeUnits = dynamic(() => import('./widgets/charge-units'))
const Footer = dynamic(() => import('./widgets/footer'))
const GGMRecipe = dynamic(() => import('./widgets/news'))
const Header = dynamic(() => import('./widgets/header'))
const Heading = dynamic(() => import('./base/heading'))
const Html = dynamic(() => import('./base/html'))
const Image = dynamic(() => import('./base/image'))
const LocationAnswers = dynamic(() => import('./widgets/location-answers'))
const MobileApp = dynamic(() => import('./widgets/mobile-app'))
const News = dynamic(() => import('./widgets/news'))
const NewsList = dynamic(() => import('./widgets/news-list'))
const Partners = dynamic(() => import('./widgets/partners'))
const Price = dynamic(() => import('./widgets/price'))
const Section = dynamic(() => import('./base/section'))
const Slider = dynamic(() => import('./widgets/slider'))
const Vheicles = dynamic(() => import('./widgets/vheicles'))
const OperationRepair = dynamic(() => import('./widgets/operation-repair'))
const Video = dynamic(() => import('./base/video'))
const SoftwareInformation = dynamic(
  () => import('./widgets/software-information')
)
const PriceMethod = dynamic(() => import('./widgets/price-method'))
const ServicesRepair = dynamic(() => import('./widgets/srevices-repair'))
const Banner = dynamic(() => import('./widgets/banner'))
const Contact = dynamic(() => import('./widgets/contact'))
const QuestionAnswer = dynamic(() => import('./widgets/question-answer'))
const Offer = dynamic(() => import('./widgets/offer'))
const Products = dynamic(() => import('./widgets/products'))
const NewsContent = dynamic(() => import('./widgets/news-content'))

export default function getter(name: string) {
  switch (name) {
    case 'heading':
      return Heading
    case 'html':
      return Html
    case 'container':
      return Container
    case 'row':
      return Row
    case 'column':
      return Column
    case 'button':
      return Button
    case 'accordion':
      return Accordion
    case 'image':
      return Image
    case 'section':
      return Section
    case 'video':
      return Video
    case 'header':
      return Header
    case 'slider':
      return Slider
    case 'footer':
      return Footer
    case 'mobile-app':
      return MobileApp
    case 'charge-location':
      return ChargeLocation
    case 'charge-station':
      return ChargeStation
    case 'charge-units':
      return ChargeUnits
    case 'news':
      return News
    case 'news-list':
      return NewsList
    case 'partners':
      return Partners
    case 'answers':
      return Answers
    case 'ggm-recipe':
      return GGMRecipe
    case 'price':
      return Price
    case 'vheicles':
      return Vheicles
    case 'banner':
      return Banner
    case 'location-answers':
      return LocationAnswers
    case 'software-information':
      return SoftwareInformation
    case 'price-method':
      return PriceMethod
    case 'operation-repair':
      return OperationRepair
    case 'services-repair':
      return ServicesRepair
    case 'contact':
      return Contact
    case 'question-answer':
      return QuestionAnswer
    case 'offer':
      return Offer
    case 'evc-products':
      return Products
    case 'bizden-haberler-content':
      return NewsContent
  }
}
